<template>
  <div class="vx-row">
    <div class="centerx">
      <vs-popup class="holamundo" :title="`${modal.action} branch`" :active.sync="modal.active">
        <div class="vx-row" data-vv-scope="item-modal">
          <div class="vx-col w-1/2 mt-5">
            <label class="vs-input--label">Branch Name</label>
            <vs-input
              v-model="modal.form.name"
              class="w-full"
              name="name"
              :danger="errors.has('name')"
              :danger-text="errors.first('name')"
              v-validate="'required|min:1|max:30'"
              size="normal"
            />
          </div>
          <div class="vx-col w-1/2 mt-5">
            <label class="vs-input--label">Code</label>
            <vs-input
              v-model="modal.form.code"
              class="w-full"
              name="code"
              :danger="errors.has('code')"
              :danger-text="errors.first('code')"
              v-validate="'required|min:3|max:5'"
              size="normal"
            />
          </div>
          <div class="vx-col w-1/2 mt-5">
            <label class="vs-input--label">City</label>
            <vs-input
              v-model="modal.form.city"
              class="w-full"
              name="city"
              :danger="errors.has('city')"
              :danger-text="errors.first('city')"
              v-validate="'required|min:4|max:20'"
              size="normal"
            />
          </div>
          <div class="vx-col w-1/2 mt-5">
            <label class="vs-input--label">Phone</label>
            <vs-input
              v-model="modal.form.phone"
              class="w-full"
              name="phone"
              :danger="errors.has('phone')"
              :danger-text="errors.first('phone')"
              v-validate="'required'"
              size="normal"
            />
          </div>
        </div>
        <vs-divider />
        <div class="vx-col w-full mt-5">
          <vs-button :disabled="this.errors.items.length !== 0" @click="upsertBranch" :color="modal.action === 'create' ? 'success' : 'warning'" type="filled" class="w-full">
            {{ modal.action === 'create' ? 'Create New Branch' : 'Save changes' }}
          </vs-button>
        </div>
      </vs-popup>
    </div>
    <div class="vx-col w-3/4 mx-auto self-center">
      <vx-card>
        <div>
          <vs-input icon-after="true" icon-pack="feather" icon="icon-search" label-placeholder="Search" v-model="search.name" class="w-full no-icon-border is-label-placeholder" />
        </div>
        <br>
        <div class="vs-con-loading__container" id="div-with-loading">
          <vs-table stripe :data="branches">
            <template slot="thead">
              <vs-th>Name</vs-th>
              <vs-th>Code</vs-th>
              <vs-th>City</vs-th>
              <vs-th>Action</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="tr.id" v-for="(tr) in data" :data="tr">
                <vs-td :data="tr.name">
                  {{ tr.name }}
                </vs-td>

                <vs-td :data="tr.code">
                  {{ tr.code }}
                </vs-td>

                <vs-td :data="tr.city">
                  {{ tr.city }}
                </vs-td>

                <vs-td>
                  <div class="btn-group" size="small">
                    <vs-button size="small" @click="() => loadEdit(tr)">
                      edit
                    </vs-button>
                    <vs-button size="small" @click="() => navigate(tr)">
                      manage
                    </vs-button>
                  </div>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
        <div slot="footer">
          <div class="flex">
            <vs-button color="success" @click="initForm" size="small">
              Create Branch
            </vs-button>
            <vs-pagination :total="meta.total_page" v-model="meta.page" :max="7" />
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import BranchRepo from '@/repositories/branch_repo';
import Pagination from '@/utils/mixins/pagination';
import { isEmpty } from '@/utils/helpers';

export default {
  mixins: [Pagination],
  data: () => ({
    branches: [],
    modal: {
      active: false,
      action: 'create',
      form: {
        id: null,
        name: null,
        code: null,
        phone: null,
        city: null
      }
    }
  }),
  methods: {
    loadData({
      page, per_page, search, debounce
    }) {
      this.loadingTrue();
      const func = debounce ? BranchRepo.listDebounce : BranchRepo.list;
      return func(page, per_page, search)
        .then(({ data: { data: branches, pagination: meta } }) => {
          this.branches = branches.map(item => ({
            id: item.id, name: item.name, phone: item.phone, code: item.code, city: item.city
          }));
          this.meta = meta;
          this.loadingFalse();
        });
    },
    navigate(item) {
      this.$router.push(`/branches/${item.id}`);
    },
    loadEdit(branch) {
      this.modal.form = {
        ...branch
      };
      this.modal.action = 'update';
      this.modal.active = true;
    },
    async upsertBranch() {
      const noError = await this.checkModal();
      if (noError) {
        const payload = {
          name: this.modal.form.name,
          code: this.modal.form.code,
          phone: this.modal.form.phone,
          city: this.modal.form.city
        };

        const actionIsCreate = this.modal.action === 'create';
        const action = actionIsCreate ? BranchRepo.create(payload) : BranchRepo.update(this.modal.form.id, payload);

        return action
          .then(() => {
            this.$vs.notify({
              title: 'Success',
              text: actionIsCreate ? 'New branch created' : 'Branch updated',
              color: 'success',
              position: 'bottom-center'
            });
            this.modal.active = false;
            this.clearForm();
            return this.loadData({});
          })
          .catch((err) => {
            this.$vs.notify({
              title: 'Whoops',
              text: err.message,
              color: 'danger',
              position: 'bottom-center'
            });
          });
      }
    },
    initForm() {
      this.clearForm();
      this.modal.action = 'create';
      this.modal.active = true;
    },
    clearForm() {
      this.modal.form = {
        id: null,
        name: null,
        code: null,
        phone: null,
        city: null
      };
      this.$validator.reset();
    },
    async checkModal() {
      await this.$validator.validate('name');
      return isEmpty(this.errors.items);
    },
    isEmpty
  }
};
</script>
