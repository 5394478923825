import debounce from 'debounce-promise';
import http from '../plugins/http';

const list = (page, per_page, name) => http.get('/branch', { params: { page, per_page, name } });

export default {
  detail: id => http.get(`/branch/${id}`),
  list,
  listDebounce: debounce(list, 400),
  create: payload => http.post('/branch', payload),
  update: (id, payload) => http.put(`/branch/${id}`, payload)
};
